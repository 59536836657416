@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;500;600&display=swap");

body {
  font-size: 14px;
  font-family: "Barlow", sans-serif;
  background: #fafafa;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

span,
p,
h1,
h2,
h3,
h4,
h5 {
  font-family: "Barlow", serif !important;
  font-weight: 500 !important;
}

.ant-select-selector {
  border: none !important;
  height: 38px !important;
  align-items: center;
  font-size: 16px !important;
}

h1 {
  font-size: 200%;
  text-transform: uppercase;
  color: #013871;
}

h2 {
  font-size: 160%;
  text-transform: uppercase;
  color: #013871;
}

h3 {
  font-size: 140%;
  color: #013871;
}

.mt {
  margin-top: 30px;
}

.mb {
  margin-bottom: 30px;
}

.pagetitle h1 {
  font-size: 200%;
  line-height: 1;
  color: #013871;
}

.pagetitle h3 {
  line-height: 0.8;
}

#main {
  margin-top: 70px;
  padding: 40px;
}

.logo img {
  max-height: 46px;
  padding-left: 30px;
}

.header {
  height: 70px;
  box-shadow: none;
  padding-left: 0;
  color: #fff;
  text-align: center;
  box-shadow: 0px 2px 10px rgba(1, 41, 112, 0.1);
}

.form-control,
.form-select,
.customDropdown,
.card,
.btn {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.02);
  border: 1px solid #e8ecef;
  border-radius: 8px;
}

.rowMinus {
  margin-left: -20px;
  margin-right: -20px;
}

.rounded-circle {
  box-shadow: 0 0 0 1px #adbaca;
}

.required {
  color: red;
}

.btn {
  font-weight: 500;
  font-size: 14px;
  padding: 6px 12px;
  min-width: 100px;
}

.btn-secondary {
  color: #fff;
  background-color: #e9f1f9;
  border-color: #c5cfdc;
}

.btn.disabled,
.btn:disabled {
  color: #e3e3e3;
  background-color: transparent;
  border-color: #e3e3e3;
}

.btn-secondary.disabled img,
.btn:disabled img {
  opacity: 0.2;
}

.btn-auto {
  min-width: inherit;
}

.txt-medium {
  font-weight: 500;
}

.txt-gray {
  color: #aeaeae;
}

.txt-black {
  color: #000;
}

.txt-md {
  font-size: 15px;
}

.txt-sm {
  font-size: 12px;
}

.line-sm {
  line-height: 1;
}

.txt500 {
  font-weight: 500;
}

.txtR {
  text-transform: inherit;
}

.shadow0 {
  box-shadow: none !important;
}

.form-check-input:checked {
  background-color: #2b8635;
  border-color: #2b8635;
}

.btn-icon {
  padding: 0px;
  min-width: inherit;
  border-radius: 6px;
}

.btn-icon2 {
  padding: 3px;
  min-width: inherit;
  border-radius: 6px;
}

.btn-icon3 {
  padding: 6px 4px;
  min-width: inherit;
}

.btn-icon-lg {
  width: 48px;
  height: 48px;
}

.btn-icon-gray {
  background: #f9f9f9 !important;
  border: #eaeaea solid 1px !important;
  padding: 0px;
  min-width: inherit;
}

.btn-border {
  border: #013871 solid 1px !important;
  transform: rotate(3.142rad);
}

.btn-primary {
  background-color: #013871;
  border-color: #013871;
}

.btn-primary-w {
  background-color: #fff;
  border-color: #fff;
}

.btn-transparent {
  color: #000;
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}

.btn-link {
  color: #013871;
  box-shadow: none;
  border: 0;
  padding: 0;
  text-decoration: none;
}

.btn-link>i {
  font-size: 24px;
  vertical-align: middle;
  margin-right: 5px;
}

.btn-lg {
  font-size: 16px;
  padding: 11px 30px;
  min-width: 150px;
}

.header .bi {
  font-size: 32px;
  padding-left: 10px;
  cursor: pointer;
  color: #012970;
}

/** form **/
.form-label {
  font-weight: 500;
  color: #000;
}

.form-check-input {
  margin-top: 0px;
}

.form-control,
.form-select {
  padding: 14px 20px;
  font-size: 18px;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e2e2e2;
  color: #aeaeae;
}

.form-label {
  margin-bottom: 0.7rem;
}

.inputSec-icon {
  position: relative !important;
}

.inputSec-icon .react-datepicker-wrapper {
  width: 100%;
}

.inputSec-icon>span {
  position: absolute;
  top: -5px;
  right: 10px;
  font-size: 36px;
}

.inputSec-icon .custom-drop-down-span {
  top: -3px !important;
}

.inputSec-icon .custom-drop-down-span .custom-drop-down-image {
  height: 32px;
  width: 32px;
  padding: 4px;
  object-fit: contain;
}

.customDropdown {
  overflow: hidden;
  background: #fff;
}

.customDropdown select {
  background: transparent;
  padding: 18px 20px;
  line-height: 1;
  border: 0;
  border-radius: 0;
}

.customDropdown {
  position: relative;
}

.customDropdown:before {
  content: "";
  position: absolute;
  right: 18px;
  top: 22px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #c1c1c1;
}

.customDropdown:after {
  content: "";
  position: absolute;
  right: 18px;
  top: 18px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #fff;
}

.customDropdown select>option {
  background: #fff;
  color: black;
}

/**  **/
.row {
  margin-right: calc(-0.35 * var(--bs-gutter-x));
  margin-left: calc(-0.35 * var(--bs-gutter-x));
}

.row>* {
  padding-right: calc(var(--bs-gutter-x) * 0.35);
  padding-left: calc(var(--bs-gutter-x) * 0.35);
}

.card {
  padding: 15px 25px;
  margin-bottom: 25px;
}

.card-title {
  font-size: 150%;
  color: #013871;
}

.card-content .list>div {
  margin-bottom: 4px;
}

.card-content .list>div>.icon-sm,
.card-content .list>div>.icon-md {
  height: 36px;
  display: inline-block;
  vertical-align: middle;
}

.card-content .list>div>.icon-sm img {
  width: 32px;
  display: block;
  margin-right: 6px;
}

.card-content .list .userInfo {
  vertical-align: middle;
  margin-left: 10px;
}

.card-content .list .userInfo>span {
  display: block;
  line-height: 1.4;
  font-weight: 500;
}

.card-content .list .userInfo>input {
  height: 36px;
  width: 150px;
  text-align: left;
}

.card-content .list .userInfo>span.txt-sm {
  font-weight: 400;
}

.card-content .list>div>.icon-md img {
  width: 36px;
  display: block;
  margin-right: 6px;
}

.card-content .list>div>.userInfo .custome-form-select {
  min-width: 350px;
  padding-right: 100px;
}

.card-content .icon-md img {
  width: 36px;
  display: block;
  margin-right: 6px;
}

.icon-md-bg img {
  background: #fff;
  border: 1px solid #e8ecef;
  border-radius: 20px;
  padding: 3px;
}

#main ::placeholder {
  color: #aeaeae;
}

.card-revert {
  margin-left: -20px;
  margin-right: -20px;
  border-radius: 0;
}

.header-inner {
  display: flex;
  height: 70px;
  padding-left: 20px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.header-nav .nav-profile {
  color: #fff;
}

.header-nav .nav-profile>span {
  font-size: 14px;
  font-weight: 400;
  display: inline-block;
  margin-left: 12px;
  margin-right: 12px;
}

.header-nav .nav-profile .dropdown {
  width: 36px;
  height: 36px;
  display: inline-block;
  background: #f9f9f9;
  border: #eaeaea solid 1px;
  border-radius: 4px;
  padding: 0px;
}

.header-nav .nav-profile .dropdown-toggle::after {
  display: none;
}

.header-nav {
  padding-right: 20px;
}

/** Side bar **/

.sidebar {
  top: 70px;
  padding: 20px 20px 20px 20px;
}

.sidebar-nav .nav-item {
  margin-bottom: 0px;
  margin-left: 5px;
  margin-right: 15px;
}

.sidebar-nav .nav-link {
  font-weight: 500;
}

.sidebar-nav .nav-link.collapsed {
  color: #aeaeae;
  border-bottom: #eaeaea solid 1px;
  padding: 15px 0;
  font-size: 14px;
  font-weight: 400;
}

.sidebar-nav .nav-link.collapsed>i {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.4;
}

.sidebar-nav .nav-link {
  color: #013871;
  padding: 15px 0;
  background: transparent;
  border-bottom: #eaeaea solid 1px;
  font-size: 16px;
}

.sidebar-nav .nav-link>i {
  margin-right: 18px;
  color: #013871;
  font-size: 20px;
}

.sidebar-nav .nav-link:hover {
  color: #ff5f00;
  background: none;
}

.sidebar-nav .nav-link>i.m-0 {
  margin-right: 0px;
}

.sidebar-nav li.nav-item:last-child .nav-link {
  border-bottom: 0;
}

.sidebar-nav .nav-content {
  padding: 0;
}

.sidebar-nav .nav-content a {
  font-weight: 400;
  color: #aeaeae;
  padding: 15px 0 15px 53px;
  border-top: #eaeaea solid 1px;
}

.sidebar-nav .nav-content a:hover,
.sidebar-nav .nav-content a.active,
.sidebar-nav .nav-content a:focus {
  color: #ff5f00;
}

.status {
  font-size: 14px;
  padding: 6px 12px;
  min-width: 100px;
  border-radius: 4px;
  color: #004195;
  border: #004195 solid 1px;
  display: inline-block;
  text-align: center;
}

.status.green {
  color: #2b7e22;
  border-color: #2b7e22;
}

.status.red {
  color: #d54242;
  border-color: #d54242;
}

.status.yellow {
  color: #ed9d02;
  border-color: #ed9d02;
}

.status.gray {
  color: #aeaeae;
  border-color: #aeaeae;
}

.textgreen {
  color: #2b7e22;
}

.textyellow {
  color: #ed9d02;
}

.textgray {
  color: #aeaeae;
}

.textred {
  color: #d54242;
}

.textmd {
  font-size: 16px;
  font-weight: 500;
}

.form-control:focus,
.form-select:focus,
.btn:focus,
button:focus {
  border-color: #ccc;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset,
    0px 0px 8px rgba(0, 0, 0, 0.08);
}

.btn-group-customtab .btn-lg {
  padding: 17px 25px;
  font-size: 16px;
  min-width: 180px;
  background: #f9f9f9;
  border-color: #eaeaea;
  color: #aeaeae;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 4%);
}

.btn-group-customtab .btn-lg.active {
  background: #fff;
  color: #000000;
}

.btn-group-customtab .btn-md {
  padding: 12px 25px;
  font-size: 14px;
  min-width: 140px;
  background: #f9f9f9;
  border-color: #eaeaea;
  color: #aeaeae;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 4%);
}

.btn-group-customtab .btn-md.active {
  background: #fff;
  color: #000000;
}

hr {
  margin: 0;
  opacity: 1;
  background: #e2e2e2;
}

#userinfo .nav-profile img {
  border: #dedede solid 1px;
  width: 150px;
  height: 150px;
}

.vsl-badges {
  text-align: right;
}

.vsl-badges .badge {
  background: #fff;
  font-size: 12px;
  color: #333;
  font-weight: 400;
  padding: 8px 16px 8px 8px;
}

.vsl-badges .badge>span {
  background: #ccc;
  border: #ccc solid 1px;
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 6px;
  border-radius: 3px;
}

.vsl-badges .badge.green>span {
  background: rgba(103, 172, 96, 0.4);
  border-color: #2b7e22;
}

.vsl-badges .badge.red>span {
  background: rgba(213, 66, 66, 0.4);
  border-color: #d54242;
}

.vsl-badges .badge.yellow>span {
  background: rgba(243, 167, 18, 0.4);
  border-color: #ed9d02;
}

.btn-full {
  width: 100% !important;
  min-width: inherit;
  padding: 11px 20px;
}

.table-custom .table {
  border-collapse: separate;
  border-spacing: 0 6px;
  font-size: 16px;
}

.table-custom .table tr {
  box-shadow: none;
}

.table-custom .table tr td {
  border-top: #e4e4e4 solid 1px;
  border-bottom: #e4e4e4 solid 1px;
  background-color: #fafafa;
  vertical-align: middle;
  padding: 12px 8px;
}

.table-custom .table tr td:first-child {
  padding-left: 15px;
  border-left: #e4e4e4 solid 1px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.table-custom .table tr td:last-child {
  padding-right: 15px;
  border-right: #e4e4e4 solid 1px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.table-custom .table tr td.img {
  width: 36px;
}

.table-custom .table tr td.roundSec {
  width: 36px;
}

.table-custom .table tr td.roundSec .round {
  width: 36px;
  height: 36px;
  background: #9aa3a2;
  border-radius: 50%;
  display: block;
  text-align: center;
  line-height: 36px;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
}

.table-custom .table tr td.roundSec .round2 {
  line-height: 32px;
}

.table-custom .table tr td .icon-arrow-left {
  font-size: 22px;
  color: #2b8635;
}

.table-custom .table tr td .icon-arrow-right {
  font-size: 22px;
  color: #d54242;
}

.table-custom .table tr td.currency .iconCurreny-sm {
  vertical-align: middle;
}

.table-custom .table tr td.currency .iconCurreny-sm>img {
  width: 16px;
  max-width: inherit;
}

.table-custom .table tr td.currency span {
  font-size: 20px;
  font-weight: 500;
}

.table-custom .table tr td.date span {
  color: #b0b6b5;
}

.table-custom .table tr td .label {
  padding: 7px 14px;
  font-weight: 500;
  font-size: 12px;
  border: #ccc solid 1px;
  min-width: 100px;
  text-align: center;
}

.table-custom .table tr td .btn-copy {
  padding: 5px 14px;
  font-weight: 500;
  border: #d6d6d6 solid 1px;
  background: #f8f8f8;
  color: #666;
}

.table-custom .table tr td .btn-copy i {
  opacity: 0.44;
}

.table-custom .table tr td .btn-sm {
  font-size: 13px;
}

.table-custom .table tr td .btn-sm2 {
  border-radius: 4px !important;
  padding: 5px 14px;
  font-weight: 500;
  line-height: 16px;
  font-size: 13px;
}

.table-custom .table tr td .btn-sm-lt {
  border-radius: 4px !important;
  padding: 5px 14px;
  font-weight: 500;
  border: #c0dac2 solid 1px;
  line-height: 16px;
  background: #f2f9ee;
  font-size: 13px;
  color: #2b8635;
}

.table-custom .table tr td .btn-sm-lt.danger {
  border: #f4c9c9 solid 1px;
  background: #faf1f1;
  color: #d54242;
}

.add>td {
  padding: 0px !important;
  background: transparent !important;
}

.add>td .table-inner,
.edit>td .table-inner {
  margin-bottom: 0px !important;
  border-spacing: 0 0px;
}

.table-inner tr td {
  border-top: 0px !important;
  border-bottom: 0px !important;
  background: #fff !important;
  padding: 0px !important;
}

.table-inner tr td:first-child {
  border-left: 0px !important;
}

.table-inner tr td:last-child {
  border-right: 0px !important;
}

.table-inner tr td input {
  border-radius: 0px !important;
  border-top: 0px;
  border-bottom: 0px;
  border-left: 0px;
  padding: 12px 12px;
  font-size: 16px;
}

.table-inner tr td:first-child input {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

.table-inner tr td .btn-icon {
  height: 48px;
  width: 48px;
  background: #013871;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 5px;
  border: 0;
}

.edit .table tr>td {
  padding: 0px !important;
  background: transparent !important;
}

.edit .table tr>td .btn-icon {
  height: 48px;
  width: 48px;
  background: #fff;
}

.edit .table tr>td input {
  border-radius: 0px !important;
  border-top: 0px;
  border-bottom: 0px;
  border-left: 0px;
  padding: 12px 12px;
  font-size: 16px;
}

.edit .table tr>td:first-child input {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

.edit .table tr>td .btn-icon {
  height: 48px;
  width: 48px;
  background: #fff;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 5px;
  border: 0;
}

.edit .table-inner tr td .btn-icon {
  background: #013871 !important;
}

.list .form-control2 {
  padding: 8px 12px;
  text-align: center;
}

.list textarea.form-control {
  padding: 8px 12px;
  text-align: left;
  font-size: 16px;
}

.inputSec-icon2>span {
  right: 20px;
}

.form-control2 {
  padding: 12px 20px;
}

/* button:focus, */
button:hover {
  background-color: #c6dbf0 !important;
  border: inherit !important;
}

.table-custom .table-user tr td {
  padding-top: 8px;
  padding-bottom: 8px;
}

.table-custom .table-user tr td:first-child {
  width: 50px;
}

.table-custom .table-user .btn-icon {
  background: transparent;
  border: 0;
}

.table-custom .table-user .btn-icon>img {
  width: 48px;
}

.table-custom .userInfo {
  margin-left: 0px !important;
}

@media only screen and (max-width: 480px) {
  .header-inner {
    background: transparent;
    padding-left: 6px;
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .logo {
    background: url("../img/Logo.svg") no-repeat center;
    width: 100%;
    height: 40px;
    background-size: contain;
    margin: auto;
    text-align: center;
  }

  .logo img {
    display: none;
  }

  #main {
    margin-top: 60px;
    padding: 20px;
  }

  .sidebar {
    padding-top: 20px;
  }

  .btm-bar {
    margin-top: 20px;
  }

  .btm-bar .pagination {
    display: none;
  }

  .btm-bar .btn {
    width: 100%;
  }

  .btm-bar div.textmd.textgray {
    display: none !important;
  }
}