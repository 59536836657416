.navigation-back-arrow {
  height: 36px;
  width: 36px;
  border-radius: 50%;
  background-color: white;
  font-size: 18px;
  color: #013871;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  position: relative;
  left: -5px;
}

/* ========== */

.custom-search-group {
  position: relative;
  /* width: 200px !important; */
  padding: 5px 10px 6px 10px !important;
}

.hover:hover {
  cursor: pointer;
}

.custom-search-group .search-wrapper {
  position: absolute;
  z-index: 500;
  left: 0px;
  top: 30px;
  border-width: 0px 1px 1px;
  border-top-style: initial;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-top-color: initial;
  border-right-color: #e8ecef;
  border-bottom-color: #e8ecef;
  border-left-color: #e8ecef;
  border-image: initial;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  /* background-color: rgb(250, 250, 250); */
  background-color: #ffffff;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
}

.custom-search-group .search-wrapper>.search-input {
  padding: 4px 10px !important;
  position: sticky;
  margin: 4px;
  width: calc(100% - 8px) !important;
  top: 4px;
}

.custom-search-group .search-wrapper .item-card {
  width: 100%;
  padding: 5px;

}

.custom-search-group .search-wrapper .item-card-hover:hover {
  background-color: #004696;
  color: white;
  cursor: pointer;
}

.custom-search-group .search-wrapper .item-card-hover span b {
  font-size: 14px;
}

.custom-select-details-page {
  padding: 14px 10px !important;
  border: 0;
  width: 100%;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.dlvryTime-td,
.qty-td,
.price-td {
  max-width: 60px;
}

.dlt-td {
  width: 30px;
}

table tbody tr td .tool-tip {
  top: 16px;
  right: 14px;
  color: #013871;
  opacity: 1;
}

table tbody tr td .react-tooltip {
  max-width: 300px;
  z-index: 900;
}

.card-content .costcenter .form-check {
  min-height: 1.5rem;
  padding-left: 0 !important;
  margin-bottom: .125rem;
}

.card-content .costcenter input[type='radio'] {
  width: 30px;
  height: 30px;
  border-radius: 50px;
  top: 0px;
  left: 0px;
  position: relative;
  background-color: #ffffff;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 1px solid grey;
}

.card-content .costcenter input[type='radio']:after {
  width: 30px;
  height: 30px;
  border-radius: 50px;
  top: 0px;
  left: 0px;
  position: relative;
  background-color: #ffffff;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 1px solid grey;
}

.card-content .costcenter input[type='radio']:checked:after {
  width: 30px;
  height: 30px;
  border-radius: 50px;
  top: 0px;
  left: 0px;
  position: relative;
  background-color: #000000;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 1px solid grey;
}

.CreatableSelect {
  border-radius: 8px;
}

.CreatableSelect .css-1rmx3ar-control:hover {
  border-color: #e8ecef;
}

.CreatableSelect .css-xc2iwz-control:hover {
  border-color: #ccc;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset, 0px 0px 8px rgba(0, 0, 0, 0.08);
}

.discount .discount-input input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button,
input[type=number] {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  -webkit-appearance: none;
  margin: 0;

  -moz-appearance: textfield;
}

.discount .discount-amount {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.form-control:focus {
  border: 1px solid #e8ecef !important;
  border-right: none !important;
}