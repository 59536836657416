.pagetitle {
    .toggle-btn {
        border: none;
        font-size: 1.2rem;
    }

    .active {
        border: 1px solid #013871;
        border-radius: 5px;
        background-color: #013871;
        color: white;
    }

    .active:hover {
        border: 1px solid #013871;
        border-radius: 5px;
        background-color: #013871;
        color: white;
    }
}