.custom-select-details-group .save-and-close-btn {
  height: 46px;
  width: 46px;
  background-color: #013871;
}

.icon-list {
  height: 25px;
  width: 25px;
}

.edit-details {
  position: relative;
  display: flex;
  align-items: center;
}

.edit-details .card-title {
  cursor: pointer;
}

.icon-edit-details {
  position: absolute;
  right: 0;
  height: 37px;
  width: 37px;
}

.dashboard-order-status-icon-btn {
  height: 37px;
  width: auto;
  padding: 4px 6px !important;
  color: #808080 !important;
  border: 1px solid #c5cfdc;
}

.card-loader-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(128, 128, 128, 0.5);
  top: 0;
  z-index: 2000;
  left: 0;
  display: flex;
  align-items: center;
  border-radius: 8px;
  justify-content: center;
}

.card-loader-wrapper .card-loader {
  margin: auto;
}

.react-datepicker-wrapper input {
  background: url("../../assets/img/icon-calendar.svg") no-repeat right;
  background-size: 37px;
  background-origin: content-box;
  padding-right: 10px;
  background-color: #fff;
}

.order-section .order-status-dropdown .dropdown-menu {
  transform: translate3d(0, -100%, 0px) !important;
  right: 0;
  left: auto !important;
  overflow: hidden;
  border-radius: 6px;
}

.order-section .order-status-dropdown .dropdown-menu .dropdown-item {
  /* text-align: center; */
}

.order-section .order-status-dropdown .dropdown-menu .dropdown-item.active {
  background-color: #0137711a;
}

.order-section .order-status-dropdown .dropdown-menu .dropdown-item.active .order-status-text {
  color: black;
  font-weight: 500;
}

.date-time-responsive {
  display: flex;
  justify-content: start;
  gap: 2px;
}

@media (max-width: 576px) {
  .date-time-responsive {
    flex-direction: column;
  }
}